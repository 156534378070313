<template>
  <div>
    <Header></Header>
    <div class="main">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer1.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style  scoped>
.main{
  overflow: hidden;
}
</style>