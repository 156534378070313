<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
<style>
.bg{
  width: 100%;
  height: 100%;
}
.flex_sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
a:link,
a:visited {
    text-decoration: none;
    color: #270f0f;
    transition: all 0.3s;
}
ul,
ol {
    list-style: none;
}

/* 禁止选中图片 */
img {
    user-select: none;
}
.h2 {
    font-weight: normal;
    font-size: 27px;
    padding-bottom: 50px;
    margin-top: 50px;
    background: url(~@/assets/images/nav_bg02.png) no-repeat;
}
</style>

