<template>
  <div>
    <!-- 页面尾部 -->
    <footer>
      <div class="footer_main">
        <div class="address">
          <img src="@/assets/logo.png" class="footer_logo" />
          <p class="address_text">四川省成都市武侯区商鼎国际</p>
        </div>
        <div class="links">
          <h3>快速链接</h3>
          <ul>
            <li
              v-for="item in navList"
              :key="item.id"
              @click="jumpUrl(item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div> 
      </div>
      <div class="footer_link">
          <a target="_blank" href="https://beian.miit.gov.cn">&copy; 蜀ICP备2023000788号-1 <img src="@/assets/images/wd.png" /></a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        // { name: "首页", id: 0 },
        { name: "关于我们", id: 1 },
        // { name: "产品展示", id: 2 },
        // { name: "新闻动态", id: 3 },
        // { name: "在线留言", id: 4 },
        { name: "联系我们", id: 5 },
      ],
    };
  },
  methods: {
    // 跳转链接
    jumpUrl(id) {
      switch (id) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/about");
          break;
        case 2:
          this.$router.push("/produce");
          break;
        case 3:
          this.$router.push("/news");
          break;
        case 4:
          this.$router.push("/message");
          break;
        case 5:
          this.$router.push("/relation");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style  scoped>
/* 页面尾部 */
footer {
  margin: 0 auto;
  width: 100%;
  height: 200px;
  background: #141414;
  color: #fff;
}
.footer_main {
  padding-top: 72px;
  height: 200px;
  box-sizing: border-box;
  padding: 0 5%;
    display: flex;
    justify-content: space-around;
}
.footer_logo {
  width: 250px;
  height: 150px;
}

.links li {
  color: #818388;
  width: 160px;
  height: 30px;
  cursor: pointer;
}
.links h3 {
  color: #fff;
  font-weight: normal;
  padding-bottom: 50px;
  background: url(../assets/images/line_bg.png) no-repeat;
}
.search {
  float: right;
  position: relative;
  margin-right: 60px;
}
.search h3 {
  color: #fff;
  font-weight: normal;
  padding-bottom: 50px;
  background: url(../assets/images/line_bg.png) no-repeat;
}
.search input {
  width: 321px;
  height: 45px;
  background: #333;
  color: #fff;
  border: 0;
  outline: 0;
  padding-left: 10px;
  font-size: 16px;
  margin-bottom: 50px;
}
.search button {
  width: 100px;
  height: 47px;
  background: #3aa27f;
  color: #fff;
  border: 0;
  outline: 0;
  position: absolute;
  left: 233px;
  top: 75px;
  cursor: pointer;
}
.search input::placeholder {
  color: #767676;
}
.search p .weibo {
  background: url(../assets/images/fenxiang.png) no-repeat;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin: 0 5px;
}
.search p .weixin {
  background: url(../assets/images/fenxiang.png) no-repeat -42px 0;
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
footer .footer_link {
  height: 73px;
  line-height: 73px;
  width: 100%;
  text-align: center;
  color: #fff !important;
  font-size: 12px;
  background: #0c0c0c;
}
footer img {
    margin-left: 5px;
    width: 16px;
    vertical-align: middle;
  }
.address_text{
    margin-top: -20px;
    margin-left: 45px;
    font-size: 14px;
}
a{
  color: #fff;
}
</style>
