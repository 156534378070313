<template>
  <div class="bg">
    <!-- 头部顶部 -->
    <div class="topnav flex_sb">
        <p>测试网站，如有任何侵权行为，请立即联系我们做出整改</p>
        <p class="show_number">
          <span><img src="@/assets/images/tel.png" /> 陈先生： 13739421225</span>
          <span><img src="@/assets/images/mail.png" /> 13739421225@163.com </span>
        </p>
    </div>
    <!-- 头部导航 -->
    <header class="flex_sb">
        <div class="logo">
          <img src="@/assets/logo.png" alt="成都弈尘云" />
        </div>
        <nav>
          <span
            v-for="item in navList"
            :key="item.id"
            @click="jumpUrl(item.id)"
            >{{ item.name }}</span
          >
        </nav>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { name: "首页", id: 0 },
        { name: "关于我们", id: 1 },
        // { name: "产品展示", id: 2 },
        { name: "联系我们", id: 5 },
      ],
      isnavList: 0,
    };
  },

  mounted() {
   
  },
  methods: {
    jumpUrl(id) {
      localStorage.setItem("navId", id);
      this.isnavList = id;
      switch (id) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/about");
          break;
        case 2:
          this.$router.push("/produce");
          break;
        case 3:
          this.$router.push("/news");
          break;
        case 4:
          this.$router.push("/message");
          break;
        case 5:
          this.$router.push("/relation");
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style  scoped>

/* 头部顶部 */
.topnav {
  width: 100%;
  height: 45px;
  background: #444;
  color: #fff;
  font-size: 12px;
  padding: 0 5%;
  box-sizing: border-box;
}
.topnav p span {
  display: inline-block;
  margin-left: 30px;
}
.topnav p span img {
  vertical-align: middle;
}
/* 头部导航 */
header {
  width: 100%;
  height: 80px;
  background: #fff;
  padding: 0 5%;
  box-sizing: border-box;
}
header  img {
  width: 200px;
  vertical-align: middle;
}
header nav span {
  cursor: pointer;
  text-align: center;
  margin: 0 25px;
}
.container span:hover {
  background: url(../assets/images/nav_bg02.png) no-repeat center 35px;
}
@media screen and (max-width:500px) {
  .bg{
    font-size: 12px;
  }
  .show_number{
    display: none;
  }
  header{
    height: 60px;
  }
  header  img{
    width: 100px;
    margin-left: -20px;
  }
  header nav span{
    margin: 0 10px;
  }
}
</style>
